import React, { memo } from 'react'
import Layout from 'components/Layout/Layout'
import Banner from 'components/Banner/Banner'
import styled from 'styled-components'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import ServicesScrollSec from 'components/ServicesScrollSec/ServicesScrollSec'
import { md } from 'config/variables'
import { images } from 'config/images'

import FeatureResources from 'components/FeatureResources/FeatureResources'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import BuildApplication from 'components/BuildApplication/BuildApplication'
import ProcessFlow from 'components/ProcessFlow/ProcessFlow'
import CustomDevelopment from 'components/CustomDevelopment/CustomDevelopment'
import CaseStudySlider from 'components/CaseStudySlider/CaseStudySlider'
import Aboutprojectform from 'components/Aboutprojectform/Aboutprojectform'
import WorkFlow from 'components/WorkFlow/WorkFlow'
import IndustriesServe from 'components/IndustriesServe/IndustriesServe'
import { cloudNativeEngProps } from 'components/Props/cloud-native-engineering'
import SimformGuaranteeSec from 'components/SimformGuaranteeSec/SimformGuaranteeSec'

export const DigitalProductEngServicesPage = styled.div`
  .banner {
    .banner-content {
      max-width: 1110px;
      p {
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 30px;
      }
    }
    .banner-img {
      display: block;
    }
  }
  .buildapp-section {
    margin-bottom: 0px;
  }
  .container {
    .service-right-wrapper {
      .service-right-section {
        .service-content-block {
          .blog-listing-wrapper {
            .blog-listing {
              .single-blog-card {
                figure {
                  min-height: 177px;
                  ${md(`        
                    min-height: 151px;
                  `)}
                }
              }
            }
          }
        }
      }
    }
  }
  .feature-resource-section {
    background: linear-gradient(to top, #eef2ff, #ffffff);
    .contact-info-card {
      display: none;
    }
  }
`

const SDSH = memo(props => {
  const { data } = props
  const { location } = props
  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  let posterImage = [props.data.posterThumbImage.childImageSharp.fluid]
  let processFlowItem = [
    props.data.process1.childImageSharp.fluid,
    props.data.process2.childImageSharp.fluid,
    props.data.process3.childImageSharp.fluid,
    props.data.process4.childImageSharp.fluid,
    props.data.process5.childImageSharp.fluid,
  ]
  return (
    <Layout
      mainClass="software-dev-services services-page"
      location={location}
      data={{
        img: images.contactHiren,
        img2x: images.contactHiren_2x,
        name: 'Hiren Dhaduk',
        description:
          'Creating a tech product roadmap and building scalable apps for your organization.',
      }}
    >
      <SEO
        title="Cloud Native Engineering Services"
        description="Accelerate your cloud journey with Simform’s Cloud Native Engineering Services. Build scalable digital products with our Cloud and DevOps experts."
        keywords={[`simform`]}
        links={[
          {
            href: 'https://www.simform.com/services/cloud-native-engineering/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content:
              'https://www.simform.com/services/cloud-native-engineering/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <DigitalProductEngServicesPage>
        <Banner
          bannerData={cloudNativeEngProps}
          showBannerImage={true}
          {...props}
        />
        <div className="lazy-load-div">
          <BuildApplication buildApplicationData={cloudNativeEngProps} />
        </div>
        {showContent && (
          <>
            <ServicesScrollSec
              serviceScrollSecdata={cloudNativeEngProps}
              {...props}
            />
            <ProcessFlow
              ProcessFlowData={cloudNativeEngProps.workingBenefitsProps}
              items={processFlowItem}
            />
            <CustomDevelopment
              customServicedata={cloudNativeEngProps.customDevProps}
            />
            <CaseStudySlider caseStudydata={cloudNativeEngProps} {...props} />
            <Aboutprojectform
              AboutprojectformData={cloudNativeEngProps.AboutprojectformProps}
            />
            <WorkFlow workFlowdata={cloudNativeEngProps} />
            <IndustriesServe industriesServedata={cloudNativeEngProps} />
            <SimformGuaranteeSec
              SimformGuaranteeProps={cloudNativeEngProps.SimformGuaranteeData}
              posterImage={posterImage}
            />
            <FeatureResources
              featureresourceData={cloudNativeEngProps}
              {...props}
            />
          </>
        )}
      </DigitalProductEngServicesPage>
    </Layout>
  )
})

export default SDSH

export const query = graphql`
  query {
    bannerImage: file(
      relativePath: { regex: "/cloud-native-engineering-banner@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1058) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    posterThumbImage: file(relativePath: { regex: "/poster-img.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication1: file(
      relativePath: { regex: "/blog-cicd-process-optimization@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication2: file(
      relativePath: { regex: "/blog-microservice-architecture@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication3: file(
      relativePath: { regex: "/blog-devops-outsourcing@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    customSofBlog1: file(
      relativePath: { regex: "/blog-cost-optimization@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    customSofBlog2: file(
      relativePath: { regex: "/blog-serverless-architecture@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    customSofBlog3: file(
      relativePath: { regex: "/blog-mongodb-mysql@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    SoftTestingBlog1: file(
      relativePath: { regex: "/blog-test-coverage@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    SoftTestingBlog2: file(
      relativePath: { regex: "/blog-functional-testing@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    SoftTestingBlog3: file(
      relativePath: { regex: "/blog-mobile-app-testing@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    MobileAppBlog1: file(
      relativePath: { regex: "/blog-app-performance@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    MobileAppBlog2: file(relativePath: { regex: "/blog-app-debate@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    MobileAppBlog3: file(
      relativePath: { regex: "/blog-app-database@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    webAppBlog1: file(relativePath: { regex: "/blog-build-scalable@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    webAppBlog2: file(
      relativePath: { regex: "/blog-build-ecommerce@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    webAppBlog3: file(relativePath: { regex: "/blog-react-vue@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    DedicSoftBlog1: file(
      relativePath: { regex: "/blog-outsourcing-lessons@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    DedicSoftBlog2: file(
      relativePath: { regex: "/blog-ecommerce-app@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    DedicSoftBlog3: file(
      relativePath: { regex: "/blog-healthcare-app@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    softProdDevBlog1: file(
      relativePath: { regex: "/home-automation-using-iot-cover-image@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    softProdDevBlog2: file(
      relativePath: { regex: "/industrial-iot-iota-part.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    softProdDevBlog3: file(relativePath: { regex: "/blog-gps-tracking.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    apiIntegBlog1: file(relativePath: { regex: "/blog-reactnative@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    apiIntegBlog2: file(relativePath: { regex: "/blog-roundup2019@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    apiIntegBlog3: file(relativePath: { regex: "/blog-mvc-mvp@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    appDevServiceBlog1: file(
      relativePath: { regex: "/blog-messaging-app@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    appDevServiceBlog2: file(
      relativePath: { regex: "/blog-like-airbnb@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    appDevServiceBlog3: file(relativePath: { regex: "/blog-mvc-mvp@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    hireDedicDevBlog1: file(
      relativePath: { regex: "/blog-outsourcing-lessons@2x/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    hireDedicDevBlog2: file(
      relativePath: { regex: "/blog-build-scalable@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    hireDedicDevBlog3: file(
      relativePath: { regex: "/blog-ecommerce-app@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process1: file(relativePath: { regex: "/eminent-expertise@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process2: file(relativePath: { regex: "/intrinsically-agile@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process3: file(relativePath: { regex: "/end-to-end-services@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process4: file(relativePath: { regex: "/scalable-team@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process5: file(relativePath: { regex: "/unmatched-quality@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case1: file(
      relativePath: { regex: "/case-auction-mobility-frontcard@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case2: file(relativePath: { regex: "/case-safari@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case3: file(
      relativePath: { regex: "/case-mission-rabies-frontcard@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case4: file(relativePath: { regex: "/case-food-truck-frontcard@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case5: file(relativePath: { regex: "/transTms-frontcard-img@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
